import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Deze pagina bestaat niet" />
    <div className="container py-8">
      <h1 className="h1">Helaas! Deze pagina bestaat (nog) niet.</h1>
      <p className="paragraph">
        <Link to="/" className="link">
          Terug naar de homepage
        </Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
